import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stores_id: ''
  },
  getters: {
  },
  mutations: {
    setStoresId(state, data) {
      state.stores_id = data
    }
  },
  actions: {
    changeStoresId({ commit }, data) {
      commit('setStoresId', data)
    }
  },
  modules: {
  }
})
