import axios from 'axios'
import router from '../router'


const services = axios.create({
  // baseURL:'http://192.168.1.35:9502/'
  // baseURL: 'https://test.api.xiaonengpifa.com/'
  baseURL:'https://api.xiaonengpifa.com/'
})
// 2. 配置 axios 请求拦截器
services.interceptors.request.use((config) => {
  // 拦截成功
  // 2.1 获取 token
  const token = sessionStorage.token ? sessionStorage.token : ''
  // 2.2 将 token 添加到请求头的 Authorization 属性中
  // config.headers.Authorization = 'Bearer ' + token
  config.headers.token = token
  return config
}, (err) => {
  // 拦截失败
  return err
})
// 3. 配置 axios 响应拦截器
services.interceptors.response.use((res) => {
  // 响应成功
  switch (res.data.code) {
    case 201:
      alert('登录失效，请重新登录')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('car_list')
      router.replace('/login')
  }
  return res.data // { data: {} }
}, (err) => {
  // 响应失败
  // 判断错误类型，并将错误的描述添加到 err.message 属性上
  if (err && err.response) {
    return {
      data: {
        status: 0,
        type: err.response.status,
        message: err.message
      }
    }
  } else {
    // err.message = '服务器连接失败！'
    return {
      data: {
        status: 0,
        message: err.message
      }
    }
  }
})
export default services
