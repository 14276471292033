import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '登录',
		component: () => import('../views/login/login.vue')
	},
	{
		path: '/login',
		name: '登录',
		component: () => import('../views/login/login.vue')
	},
	{
		path: '/home',
		name: '首页',
		redirect: '/accounts',
		meta: {
			requireAuth: true,
		},
		component: () => import("../views/index/home.vue"),
		children: [
			{
				path: '/accounts',
				name: 'accounts',
				component: () => import("../views/accounts/accounts.vue"),
			},
			{
				path: '/add_accounts',
				name: 'add_accounts',
				component: () => import("../views/accounts/add_accounts.vue"),
			},
			{
				path: '/printer',
				name: 'printer',
				component: () => import("../views/printer/printer.vue"),
			},
			{
				path: '/order',
				name: 'order',
				component: () => import("../views/order/order.vue"),
			},
			{
				path: '/statistics',
				name: 'statistics',
				component: () => import("../views/statistics/statistics.vue"),
			},
			{
				path: '/stock',
				name: 'stock',
				component: () => import("../views/statistics/stock.vue"),
			},
			{
				path: '/sale',
				name: 'sale',
				component: () => import("../views/statistics/sale.vue"),
			},
			{
				path: '/refund',
				name: 'refund',
				component: () => import("../views/statistics/refund.vue"),
			},
			{
				path: '/inventory',
				name: 'inventory',
				component: () => import("../views/inventory/inventory.vue"),
			},
			{
				path: '/receipt',
				name: 'receipt',
				component: () => import("../views/inventory/receipt.vue"),
			},
			{
				path: '/logistics',
				name: 'logistics',
				component: () => import("../views/logistics/logistics.vue"),
			},
			{
				path: '/goods',
				name: 'goods',
				component: () => import("../views/goods/goods.vue"),
			},
			{
				path: '/sku',
				name: 'sku',
				component: () => import("../views/goods/sku.vue"),
			},
			{
				path: '/type',
				name: 'type',
				component: () => import("../views/goods/type.vue"),
			},
			{
				path: '/goods_attribute',
				name: 'goods_attribute',
				component: () => import("../views/goods/goods_attribute.vue"),
			},
			{
				path: '/ledger_statistics',
				name: 'ledger_statistics',
				component: () => import("../views/statistics/ledger_statistics.vue"),
			},
			{
				path: '/statistics_goods',
				name: 'statistics_goods',
				component: () => import("../views/statistics/statistics_goods.vue"),
			},
		]
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import("../views/notFound.vue"),
	},
]

const router = new VueRouter({
	routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
	// 判断是否有token，用户是否登录
	let token = sessionStorage.getItem('token')
	// 如果有token或是去登录页，放行
	if (token || to.path === '/login') {
		next()
		// 如果没有token强制跳转到登录页
	}else {
		next('/login')
	}
})

export default router
